<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">
		<div>
			<a-row :gutter="24">

				<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 mt-10">
					<h5>List of Schedules</h5>
				</a-col>

				<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mt-5 mb-24 text-right">
					<a-button id="btn" type="primary" @click="onCreateSchedule" v-if="isCreator">
						<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Schedule
					</a-button>
					<!-- <h4 style="color: white;" v-if="!isCreator">Silabu</h4> -->
				</a-col>

			</a-row>
			
			<div v-if="!loadingData == message.length == 0">

				<a-row :gutter="24" class="mt-20">

					<a-col class="mb-24" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24" v-for="(group, scheduleDate) in schedules" :key="scheduleDate">

						<a-row :gutter="24">

							<a-col :span="24">
								<h6 class="mb-20">{{ scheduleDate == todayDate ? 'Today' : scheduleDate }}</h6>
							</a-col>

							<a-col class="mb-24" style="height: 100%;" :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" v-for="sche in group" :key="sche.uuid">
								<a-card class=" px-5 h-100">

									<a-row :gutter="24">
										<a-col :span="24" :md="24" :lg="24" :xl="24">
											<a-row :gutter="24">
												<a-col :span="12" :xs="24" :sm="6" :md="6" :lg="6" :xl="4" class="">
													<a-avatar :size="50" class="mt-5">
														<template #icon>
															<svg class="dark:text-white pt-10" style="color: #444444; width: 28px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
															</svg>
														</template>
													</a-avatar>
												</a-col>

												<a-col :span="24" :xs="24" :sm="18" :md="18" :lg="18" :xl="20">
													
													<a-row :gutter="24" class="mt-10">
														<a-col :span="24" :md="24" :lg="16" :xl="16">
															<h6 class="mb-0 pb-0">{{ formatName(sche.name.length > 20 ? `${sche.name.substring(0, 20)}...` : sche.name) }}</h6>
														</a-col>
														
														<a-col :span="24" :md="24" :lg="8" :xl="8" class="text-right mb-10" style="height: 30px !important;">
															<a-button id="liveBtn" type="text" size="small" disabled v-if="checkSessionStarted(sche.startDate, sche.endDate) == 'online'" style="padding-left: 8px; padding-right: 8px; height: 30px !important;">
																<font-awesome-icon icon="fa-solid fa-circle" class="" style="font-size: 5px !important;"/> 
																Live 
															</a-button>
														</a-col>
													</a-row>

													<a-row :gutter="24">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<label>Start at {{ `${$Moment(sche.startDate).format('hh:mm A')} to ${$Moment(sche.endDate).format('hh:mm A')}` }} </label>
														</a-col>
													</a-row>

													<a-row :gutter="24">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<small>Created by {{ `${formatName(sche.creator.firstName)} ${formatName(sche.creator.lastName)}` }} </small>
														</a-col>
													</a-row>

													<a-row :gutter="24">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<!-- <a-badge  
																style="font-size: 14px !important;" 
																:count="`📖 ${sche.chapters != null ? sche.chapters : '0'} Chapters`" 
																:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;

															<a-badge  
																style="font-size: 14px !important;" 
																:count="`🧩 2 Quizzes`" 
																:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> -->
														</a-col>
													</a-row>

													
												</a-col>

											</a-row>
											
										</a-col>
									</a-row>
									
									<!-- <a-row :gutter="24">
										<a-col :span="24" :md="24" :lg="24" :xl="24">
											<p>
												{{ tpc.description }}
											</p>
										</a-col>
									</a-row> -->

									<a-row class="mt-5" v-if="isCreator">
										<a-col :span="24" class="text-right mt-2">
											<a-button type="text" size="small" id="editBtn" class="text-silabu" :href="`/my-classes/view/${classDetails.uuid}/live-class`" target="_blank" v-if="checkSessionStarted(sche.startDate, sche.endDate) == 'online'">
												Join Class
											</a-button>

											<a-button type="text" size="small" id="editBtn" class="text-silabu" @click="onEditSchedule(sche)">
												<a-icon type="form" theme="outlined"/> Edit
											</a-button>
										
											<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(sche.uuid)" danger>
												<a-icon type="delete" theme="outlined" /> Delete
											</a-button>
										</a-col>
									</a-row>
								</a-card>
							</a-col>
							
						</a-row>
					</a-col>

				</a-row>


			</div>



			<div v-if="!loadingData && Object.keys(schedules).length === 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../../public/images/no_data.png" alt="" width="100%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No schedules found</h6>
						
					</a-col>

				</a-row>
			</div>


			<div v-if="loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>

			
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="schedule.showModal" :footer="null">

			<h4 class="text-center">{{  schedule.isCreate ? 'Add Topic' : 'Edit Topic'}}</h4>

			<a-form
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Title" :colon="false">
					<a-input 
						class="mt-0"
						v-decorator="['name', { rules: [{ required: true, message: 'Please input title of the schedule!' }] },]" 
						placeholder="Title"/>
				</a-form-item>
				
				<a-form-item class="mb-10" label="Start Date" :colon="false">
					<a-date-picker
						name="startDate"
						format="DD MMM YYYY"
						style="width: 100%"
						v-decorator="[ 'startDate',  { rules: [{ required: true, message: 'Please select start date of the schedule!' }] } ]"
						placeholder="Start Date" />
				</a-form-item>


				<a-form-item class="mb-10" label="Start Time" :colon="false">
					<a-time-picker 
						class="w-100"
						style="width: 100%"
						v-decorator="[ 'startTime',  { rules: [{ required: true, message: 'Please select start time of the schedule!' }] } ]"
						format="HH:mm" />
				</a-form-item>

				<a-form-item class="mb-10" label="End Date" :colon="false">
					<a-date-picker
						name="endDate"
						format="DD MMM YYYY"
						style="width: 100%"
						v-decorator="[ 'endDate',  { rules: [{ required: true, message: 'Please select end date of the schedule!' }] } ]"
						placeholder="End Date" />
				</a-form-item>

				<a-form-item class="mb-10" label="End Time" :colon="false">
					<a-time-picker 
						class="w-100"
						style="width: 100%"
						v-decorator="[ 'endTime',  { rules: [{ required: true, message: 'Please select end time of the schedule!' }] } ]"
						format="HH:mm" />
				</a-form-item>
				
				<a-form-item id="pricing" class="mb-0 pb-0" label="" :colon="false">
					Is this repeatable &nbsp;&nbsp; <a-switch class="mt-0 pt-0"  v-decorator="['isRepeatable', { rules: [] } ]" @change="onRepeatableChange"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Repeats" :colon="false" v-if="isRepeatable">
					<a-select style="width: 100%;" placeholder="Select Options" v-decorator="[ 'repeatAfter',  { rules: [{ required: true, message: 'Please select start date of the schedule!' }] } ]">
						<a-select-option v-for="option in repeatitions"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item class="mt-20">
					<a-button type="primary" :loading="schedule.loading" id="btn" html-type="submit" class="login-form-button text-white">
						{{ schedule.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import dayjs from 'dayjs';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
				message: '',
                schedules: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
				isRepeatable: false,
				schedule: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					loading: false,
					isCreate: true,
					showModal: false,
				},

				repeatitions: [
					{ uuid: 1, name: 'Daily' },
					{ uuid: 7, name: 'Weekly' }, 
					{ uuid: 30, name: 'Monthly' },
					{ uuid: 365, name: 'Yearly' },
				],

				userDetails: {},

				todayDate: this.$Moment(new Date).format('DD MMM YYYY') 
      		}
		},
		// beforeCreate() {
		// 	// Creates the form and adds to it component's "form" property.
		// 	this.form = this.$form.createForm(this, { name: 'topic_login' });
		// },
		created() {
			this.getSchedules();
			this.getUserDetails();
		},

		methods: {

			formatName(name) {
				let formattedName = name.length > 30 ? `${name[0].toUpperCase()}${name.substring(1, 25).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },

			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					this.userDetails = JSON.parse(userInfo)
				}
			},


			onRepeatableChange(event) {
				this.isRepeatable = event;

				if(!this.isRepeatable) { 
					setTimeout(() => {
						this.form.setFieldsValue({
							repeatAfter: undefined
						});
					}, 10);
				}
			},


			filterByExpiration (schedules) {
				let today = new Date();
				let formattedDate = this.$Moment(today.toISOString()).format('DD-MM-YYYY');
				
				return schedules.filter((item) => this.$Moment(item.startDate).format('DD-MM-YYYY') >= this.$Moment().format('DD-MM-YYYY'))
			},

			async getSchedules() {

				this.loadingData = true;

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/schedules?clazz=${this.$route.params.uuid}&orderBy=startDate&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						//  = response.data.data;
						

						this.schedules = await this.filterByExpiration(response.data.data).reduce((schedule, currentSchedule) => {

							const category = this.$Moment(currentSchedule.startDate).format('DD MMM YYYY');
							if (!schedule[category]) {
								schedule[category] = [];
							}
							schedule[category].push(currentSchedule);
							return schedule;
						}, {});
						// console.log(this.schedules)
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			onCreateSchedule() {

				this.schedule.uuid = null;
				this.schedule.isCreate = true;
				this.schedule.showModal = true;

				setTimeout(() => {
					this.form.setFieldsValue({
						name: null,
						startDate: null,
						startTime: null,
						endDate: null,
						endTime: null,
						isRepeatable: false,
						repeatAfter: undefined,
					});
				}, 10);
			},


			onEditSchedule(item) {

				console.log(item)

				this.schedule.uuid = item.uuid;
				this.isRepeatable = item.isRepeatable;
				this.schedule.isCreate = false;
				this.schedule.showModal = true;

				setTimeout(() => {
					this.form.setFieldsValue({
						name: item.name,
						isRepeatable: item.isRepeatable,
						repeatAfter: item.repeatAfter,
						startDate: item.startDate,
						startTime: item.startDate,
						endDate: item.endDate,
						endTime: item.endDate,
					});
				}, 10);
			},

			checkSessionStarted(startDate, endDate) {
				let todayDate = this.$Moment(new Date).format('DD-MM-YYYY'); 
				let currentTime = this.$Moment(new Date).format('hh:mm A');
				
				let scheduleDate =  this.$Moment(startDate).format('DD-MM-YYYY');
				let scheduleStartTime =  this.$Moment(startDate).format('HH:mm');
				let scheduleEndTime =  this.$Moment(endDate).format('HH:mm');

				if(todayDate == scheduleDate) {
					if(currentTime >= scheduleStartTime && currentTime <= scheduleEndTime) {
						return 'online';
					}else if(currentTime > scheduleEndTime) {
						return 'over'
					}
					else if(currentTime <= scheduleStartTime) {
						return 'start-soon'
					}
				}else{
					return 'not-yet'
				}

				console.log()
			},


			formatDateToUTC(date, time) {
				return new Date(
					this.$Moment(date).format('YYYY'), 
					this.$Moment(date).format('MM') - 1, 
					this.$Moment(date).format('DD'), 
					this.$Moment(time).format('HH'),
					this.$Moment(time).format('mm'),
					this.$Moment(time).format('ss'),
					0,
				).toISOString();
			},



			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {

					if ( !err ) {

						this.schedule.loading = true;

						let payload = values;

						payload.clazz = this.$route.params.uuid;
						payload.startDate = this.formatDateToUTC(values.startDate._d != null ? values.startDate._d : values.startDate, values.startTime._d != null ? values.startTime._d : values.startTime);
						payload.endDate = this.formatDateToUTC(values.endDate._d != null ? values.endDate._d : values.endDate, values.endTime._d != null ? values.endTime._d : values.endTime);
						payload.creator = this.userDetails.uuid;  
						
						console.log(payload)
						if(this.schedule.isCreate) {

							let url = `${this.$BACKEND_URL}/schedules`;

							this.$AXIOS.post(url, payload).then(async(response) => {
								
								this.schedule.uuid = null,
								this.schedule.title = null,
								this.schedule.position = null,
								this.schedule.description = null,
								this.schedule.isCompleted = false,
								this.schedule.isCreate = true,
								this.schedule.showModal = false,

								this.form.resetFields()

								this.getSchedules()

								this.notify('Schedule has been created successfully', 'success')

								this.schedule.loading = false;

							}).catch(async(error) => {
								
								this.schedule.loading = false;

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{

							let url = `${this.$BACKEND_URL}/schedules/${this.schedule.uuid}`;

							this.$AXIOS.patch(url, payload).then(async(response) => {
								
								this.schedule.uuid = null,
								this.schedule.title = null,
								this.schedule.position = null,
								this.schedule.description = null,
								this.schedule.isCompleted = false,
								this.schedule.isCreate = true,
								this.schedule.showModal = false,

								this.form.resetFields()

								this.getSchedules()

								this.notify('Schedule was updated successfully', 'success')

								this.schedule.loading = false;

							}).catch(async(error) => {
								
								this.schedule.loading = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}

					}else{
						this.schedule.loading = false;
					}
				})
			},


			async onDelete(uuid) {

				// e.preventDefault();
				
				this.schedule.loading = true;

				let url = `${this.$BACKEND_URL}/schedules/${uuid}`;

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Topic was deleted successfully', 'success')

					this.getSchedules();

					this.schedule.loading = false;

				}).catch(async(error) => {
					
					this.schedule.loading = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>