<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">
		<div>
			<a-row :gutter="24">

				<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 mt-10">
					<h5>List of Topics</h5>
				</a-col>

				<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mt-5 mb-24 text-right">
					<a-button id="btn" type="primary" @click="onCreateTopic" v-if="isCreator">
						<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Topic
					</a-button>
					<h4 style="color: white;" v-if="!isCreator">Silabu</h4>
				</a-col>

			</a-row>
			
			<div v-if="!loadingData == message.length == 0">

				<a-row :gutter="24">

					<a-col class="mb-24" style="height: 100%;" :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" v-for="(tpc, index) in topics" :key="tpc.uuid">
						<a-card class=" px-5 h-100">
							<div class="text-dark p-0 m-0" style="text-decoration: none !important;" @click="!isCreator && lockTopic(index, tpc) ? readNotesMessage(index) : navigateToLessonDetails(tpc.uuid)">
								<a-row :gutter="24">
									<a-col :span="24" :md="24" :lg="24" :xl="24">
										<a-row :gutter="24">
											<a-col :span="24" :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class=" mb-10">
												<a-avatar :size="50" class="mt-2">
													<template #icon>
														<svg class="dark:text-white pt-10" style="color: #444444; width: 28px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
															<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.5 4h-13m13 16h-13M8 20v-3.3c0-.5.1-.9.4-1.2l1.6-2.9a1 1 0 0 0 0-1.2L8.4 8.5A2 2 0 0 1 8 7.3V4h8v3.3c0 .5-.1.9-.4 1.2L14 11.4a1 1 0 0 0 0 1.2l1.6 2.9c.3.3.4.7.4 1.2V20H8Z"/>
														</svg>
														
													</template>
												</a-avatar>
											</a-col>

											<a-col :span="24" :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
												<a-row :gutter="24">
													<a-col :span="24" :sm="24" :md="12" :lg="12" :xl="12">
														<label>Topic {{ tpc.position != null ? tpc.position : index + 1 }}</label>
													</a-col>
													<!-- " -->
													<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right" v-if="!isCreator && lockTopic(index, tpc.uuid)">
														<svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" style="color: #BBBBBB">
															<path fill-rule="evenodd" d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
														</svg>
													</a-col>

												</a-row>
												<a-row :gutter="24">
													<a-col :span="24" :md="24" :lg="24" :xl="24">
														<h6>{{ formatName(tpc.title) }}</h6>
													</a-col>
												</a-row>

												<a-row :gutter="24">
													<a-col :span="24" :md="24" :lg="24" :xl="24">
														<a-badge  
															style="font-size: 14px !important;" 
															:count="`📖 ${tpc.chapters != null ? tpc.chapters : '0'} Chapters`" 
															:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;

														<!-- <a-badge  
															style="font-size: 14px !important;" 
															:count="`🧩 0 Quizzes`" 
															:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> -->
													</a-col>
												</a-row>

												
											</a-col>

										</a-row>
										
									</a-col>
								</a-row>
								
								<!-- <a-row :gutter="24">
									<a-col :span="24" :md="24" :lg="24" :xl="24">
										<p>
											{{ tpc.description }}
										</p>
									</a-col>
								</a-row> -->
							
							</div>
								<a-row class="mt-5" v-if="isCreator">
									<a-col :span="24" class="text-right mt-2">
										<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu :href="`/my-classes/view/${$route.params.uuid}/topic/${tpc.uuid}`">
											<a-icon type="eye" theme="outlined"/> View
										</a-button>
										
										<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditTopic(tpc)"><a-icon type="form" theme="outlined"/> Edit</a-button>
									
										<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(tpc.uuid)" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
									</a-col>
								</a-row>
						</a-card>
					</a-col>

				</a-row>


			</div>


			<div v-if="!loadingData && topics.length == 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../../public/images/no_data.png" alt="" width="100%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No topics found</h6>
						
					</a-col>

				</a-row>
			</div>


			<div v-if="loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>

			<div v-if="!loadingData && message.length > 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<label>{{message}}</label>

					</a-col>

				</a-row>
			</div>
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="topic.showModal" :footer="null">

			<h4 class="text-center">{{  topic.isCreate ? 'Add Topic' : 'Edit Topic'}}</h4>

			<a-form
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Title" :colon="false">
					<a-input 
						class="mt-0"
						v-decorator="['title', { rules: [{ required: true, message: 'Please input your title of the topic!' }] },]" 
						placeholder="Title"/>
				</a-form-item>
				
				<a-form-item class="mb-10" label="Position" :colon="false">
					<a-input 
						type="number"
						v-decorator="['position', { rules: [{ required: true, message: 'Please input your topic position!' }] },]" 
						placeholder="Position"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Description" :colon="false">
					<a-textarea 
						:rows="4"
						v-decorator="['description', { rules: [] } ]" 
						placeholder="Description" />
				</a-form-item>

				
				<a-form-item id="pricing" class="mb-0 pb-0" label="" :colon="false">
					Completed &nbsp;&nbsp; <a-switch class="mt-0 pt-0"  v-decorator="['isCompleted', { rules: [] } ]"/>
				</a-form-item>

				<a-form-item class="mt-20">
					<a-button type="primary" :loading="topic.loading" id="btn" html-type="submit" class="login-form-button text-white">
						{{ topic.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
				message: '',
                topics: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
				topic: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					isCompleted: false,
					isCreate: true,
					showModal: false,
				},
				lessons: [],
      		}
		},
		// async mounted () {
		// 	let breadcrumbs = [
		// 		{ title: 'Classes', url: '/classes', isActive: false },
        //         { title: 'Classroom', url: `/my-classes/view/${this.$route.params.uuid}`, isActive: false },
        //         { title: 'View Topic', url: ``, isActive: true },
		// 	];
			
		// 	await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		// },
		created() {
			this.getLessons();
			this.getTopics();
			this.initializeClassDetails();
		},

		methods: {

			formatName(name) {
				let formattedName = name.length > 15 ? `${name[0].toUpperCase()}${name.substring(1, 15).toLowerCase()} ...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },


			async initializeClassDetails() {
				if(this.isCreator != null && this.classDetails != null) {
					await localStorage.setItem('isCreator', this.isCreator);
					await localStorage.setItem('classDetails', JSON.stringify(this.classDetails));
				}
			},

			navigateToLessonDetails(topicUuid) {

				this.$router.push(`/my-classes/view/${this.$route.params.uuid}/topic/${topicUuid}`);
			},

			readNotesMessage(index) {
				let topic = this.topics[index - 1]
				this.notify(`Please finish first reading notes in topic - ${topic.title}`, 'error')
			},


			async getTopics() {

				this.loadingData = true;

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/topics?clazz=${this.$route.params.uuid}&isSubtopic=false&orderBy=position&order=ASC&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.topics = response.data.data;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getLessons() {

				this.loadingData = true;

				const currentPage = 1 
				const perPage = 10000;

                let url = `${this.$BACKEND_URL}/topics?clazz=${this.$route.params.uuid}&isSubtopic=true&orderBy=position&order=ASC&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    
					this.lessons = await response.data.data;
					// .reduce((lesson, currentLesson) => {

					// 		const category = currentLesson.topic;
					// 		if (!lesson[category]) {
					// 			lesson[category] = [];
					// 		}
					// 		lesson[category].push(currentLesson);
					// 		return lesson;
					// 	}, {});

					console.log(this.lessons)
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			lockTopic(index, topicUuid) {

				const userDetails = JSON.parse(localStorage.getItem('user_details'));

				if(this.classDetails.lockTopic == true) {
					return false
				}
				else if(index > 0) {
					let topic = this.topics[index];
					let previousTopic = this.topics[index - 1];

					let topicLessons = this.lessons.filter(lesson => lesson.topic == previousTopic.uuid);

					if(topicLessons.length == 0) {
						return true;
					}

					let notesRead = topicLessons.filter(les => les.completed.includes(userDetails.uuid))

					if(notesRead.length == 0) {
						return true;
					}

					return notesRead.includes(false) ? true : false
				}else{
					return false
				}
			},


			onCreateTopic() {

				this.topic.uuid = null;
				this.topic.isCreate = true;
				this.topic.showModal = true;

				setTimeout(() => {
					this.form.setFieldsValue({
						title: null,
						position: null,
						description: null,
						isCompleted: false,
					});
				}, 10);
			},


			onEditTopic(item) {

				this.topic.uuid = item.uuid;
				this.topic.isCreate = false;
				this.topic.showModal = true;

				setTimeout(() => {
					this.form.setFieldsValue({
						title: item.title,
						position: item.position,
						description: item.description,
						isCompleted: item.isCompleted,
					});
				}, 10);
			},



			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						let payload = values;

						payload.clazz = this.$route.params.uuid;
						payload.isSubtopic = false;

						console.log(payload)

						if(this.topic.isCreate) {

							this.topic.loading = true;
							this.topic.uuid = undefined;
							payload.position = parseInt(values.position) > this.topics.length ? values.position : this.topics.length + 1;

							let url = `${this.$BACKEND_URL}/topics`;

							this.$AXIOS.post(url, payload).then(async(response) => {
								
								this.topic.uuid = null,
								this.topic.title = null,
								this.topic.position = null,
								this.topic.description = null,
								this.topic.isCompleted = false,
								this.topic.isCreate = true,
								this.topic.showModal = false,

								this.form.resetFields()

								this.getTopics()

								this.notify('Topic was saved successfully', 'success')

								this.topic.loading = false;

							}).catch(async(error) => {
								
								this.topic.loading = false;

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{
							this.topic.loading = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/topics/${this.topic.uuid}`;

							this.$AXIOS.patch(url, payload).then(async(response) => {
								
								this.topic.uuid = null,
								this.topic.title = null,
								this.topic.position = null,
								this.topic.description = null,
								this.topic.isCompleted = false,
								this.topic.isCreate = true,
								this.topic.showModal = false,

								this.form.resetFields()

								this.getTopics()

								this.notify('Topic was updated successfully', 'success')

								this.topic.loading = false;

							}).catch(async(error) => {
								
								this.topic.loading = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}
				})
			},


			async onDelete(uuid) {

				// e.preventDefault();
				
				this.topic.loading = true;

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/topics/${uuid}`;

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Topic was deleted successfully', 'success')

					this.getTopics();
					this.topic.loading = false;

				}).catch(async(error) => {
					
					this.topic.loading = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>