<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="px-20 pt-10">

			<a-row :gutter="24" style="margin-top: 0px;">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					<a-card class="px-5 pb-0 mb-0">
						<a-row :gutter="24">
							<a-col :span="24" :sm="24" :md="12" :lg="12" :xl="12">
								<h4 class="mt-3 mb-0">
									<a-avatar 
										:size="90" 
										:src="classDetails.thumbnail != null ? 
											classDetails.thumbnail : 
											'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" 
										alt=""/>
										&nbsp;&nbsp;
									{{ classDetails.name }}
								</h4>
							</a-col>

							<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right">

								<a-button id="textBtn" type="#F1EFFA" @click="copyUrl">
									<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24">
										<path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
									</svg>
									<!-- <font-awesome-icon icon="fa-solid fa-share-nodes fa-xl" /> -->
									 Share Class
								</a-button>

								<router-link 
									:to="`/my-classes/view/${classDetails.uuid}/edit`" 
									class="mr-10" 
									v-if="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid">
									<a-button 
										id="textBtn" 
										type="text" 
										class="pt-3 pb-3 text-align-center" 
										style="margin-top: 20px !important;">
										Edit Class Details
									</a-button>
								</router-link>

								<a-button 
									type="primary" 
									class="pt-3 pb-3 text-align-center" 
									style="margin-top: 20px !important;"
									:href="`/my-classes/view/${this.$route.params.uuid}/live-class`"
									target="_blank"
									:disabled="!(classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid)">
									<a-icon type="video-camera" theme="outlined" class="" style="font-size: 17px; margin-right: 7px; margin-top: 10px;" /> <span class="text-white" style="padding-bottom: 10px !important;">Live Class</span>
								</a-button>

								
							</a-col>
						</a-row>
					</a-card>
					
					<a-row class="mt-10">
						<a-col :span="24">
							<a-tabs v-model="activeTab" @change="onChangeActiveTab">
								<a-tab-pane key="topic" tab="Topic">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<Topic 
											:classDetails="classDetails" 
											:isCreator="classDetails.creator != null && userDetails.uuid != null && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-card> 
								</a-tab-pane>
								<a-tab-pane key="classroom" tab="Classroom">
									<a-card 
										class="p-0 m-0 mt-0"
										style="background: transparent; border: none;">
										<Classroom 
											:classDetails="classDetails" 
											:userDetails="userDetails"
											:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-card>
								</a-tab-pane>
								<a-tab-pane key="schedule" tab="Schedule" force-render>
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<Schedules
												:classDetails="classDetails" 
												:isCreator="classDetails.creator != null && userDetails.uuid != null && userDetails.uuid == classDetails.creator.uuid"
											/>
									</a-card>
								</a-tab-pane>
								<a-tab-pane key="classmates" tab="Classmates">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<Classmates
											:classDetails="classDetails" 
											:isCreator="classDetails.creator != null && userDetails.uuid != null && userDetails.uuid == classDetails.creator.uuid"
										/>
									</a-card>
								</a-tab-pane>
								<!-- <a-tab-pane key="notes" tab="Notes">
									<NotesDirectory 
										:classDetails="classDetails" 
										:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
									/>
								</a-tab-pane>
								<a-tab-pane key="past-papers" tab="Past Papers">
									<PastPapersDirectory 
										:classDetails="classDetails" 
										:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
									/>
								</a-tab-pane>
								<a-tab-pane key="quiz" tab="Quiz" class="bg-white">
									<Quiz 
										:classDetails="classDetails" 
										:isCreator="classDetails != null && classDetails.creator != null && classDetails.creator && userDetails.uuid == classDetails.creator.uuid"
									/>
								</a-tab-pane> -->
								<!-- <a-tab-pane key="assessment" tab="Assessment">Assessment</a-tab-pane> -->
								
							</a-tabs>
						</a-col>
					</a-row>

				</a-col>

			</a-row>
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>



<script>
	import socketIO from "socket.io-client"
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';
	import Quiz from './Quiz/Index';
	import NotesDirectory from './Notes/Directory';
	import PastPapersDirectory from './PastPapers/Directory';
	import Topic from './Topic/Index';
	import Classmates from './Classmates/Index';
	import Classroom from './Classroom/Index';
	import Schedules from './Schedules/Index';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
			Quiz,
			NotesDirectory,
			PastPapersDirectory,
			Classroom,
			Topic,
			Schedules,
			Classmates
		},

		data() {
			return {

				loadingData: false,
				activeTab: null,
				classDetails: {
                    uuid: null,
                    name: null,
                    objective: null,
                    isPublic: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    numOfSlots: null,
                    rating: null,
                    creator: {},
                    setupCompleted: null,
                    isAcademic: null,
                    price: 0,
					lockTopic: false,
                    isForTeaching: null,
                    features: [],
                    assessmentModes: [],
                    startTimestamp: null,
                    endTimestamp: null,
                    thumbnail: null,
                    createdAt: null,
                    updatedAt: null,
                },
				userDetails: {},

				socket: null,

				search: "",
				message: "Hello",
				api: null,
				room: "test romm",
				username: "rabie",
      		}
		},
		async mounted () {
			let route = this.$RouteManager.getPreviousRoute();
			console.log("========================================>")
			console.log(route);

			let breadcrumbs 

			if(route != null && route.name == 'Home') {

				breadcrumbs = [
					{ title: 'Classroom', url: `/my-classes/view/${this.$route.params.uuid}`, isActive: true },
				];

			}
			else if(route != null && route.fullPath == '/tutor-classes'){
				breadcrumbs = [
					{ title: 'Classes', url: '/tutor-classes', isActive: false },
					{ title: 'Classroom', url: `/my-classes/view/${this.$route.params.uuid}`, isActive: true },
				];
			}
			else if(route != null && route.name == 'Institution') {
				breadcrumbs = [
					{ title: 'Institutions', url: 'institutions-list', isActive: false },
					{ title: 'Classes', url: route.fullPath, isActive: false },
					{ title: 'Classroom', url: `/my-classes/view/${this.$route.params.uuid}`, isActive: true },
				];
			}
			else {
				breadcrumbs = [
					{ title: 'Classes', url: '/classes', isActive: false },
					{ title: 'Classroom', url: `/my-classes/view/${this.$route.params.uuid}`, isActive: true },
				];
			}

			
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)

			let savedbreadCrumbs = breadcrumbs

			savedbreadCrumbs[breadcrumbs.length - 1].isActive = false;

			await localStorage.setItem('classPrevBreadcrumbs', JSON.stringify(savedbreadCrumbs))
		},
		created() {
			
			this.initializeDetails();
			this.getClassDetails();
			this.recordClassAttendance()
		},

		// watch: {
        //     socket: {
		// 		handler() {
		// 			this.socket.on("new-message", (data) => {
						
		// 			});
		// 		}
		// 	},
        //     deep: true,
        // },
		methods: {


			copyUrl() {
                let link = `${this.$FRONTEND_URL}/classes/view/${this.$route.params.uuid}`;
                
                navigator.clipboard.writeText(link);

                this.notify('Link has been copied successfully', 'success')
            },

			async initializeDetails() {
				const activeTab = await localStorage.getItem('activeTab');
				const userDetails = JSON.parse(await localStorage.getItem('user_details'));

				let token = await localStorage.getItem("user_token")

				this.socket = await socketIO.connect("https://dev.silabu.com" , {
					transports: ["websocket"],
					auth: { token: `${token}` }        
				});

				// console.log(this.socket)
				// this.socket.emit('joinChatRoom', {"roomUuid": this.$route.params.uuid, "userUuid": userDetails.uuid})

				this.userDetails = userDetails;
				 this.activeTab = 'topic'
				// this.activeTab = activeTab != null && activeTab != 'csv-users' ? activeTab : 'topic'
				
			},

			async onChangeActiveTab(activeTab) {
				await localStorage.setItem('activeTab', activeTab)
			},


			async getClassDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/classes/?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.uuid = response.data.data.uuid;
                        this.classDetails.name = response.data.data.name;
                        this.classDetails.objective = response.data.data.objective;
                        this.classDetails.isPublic = response.data.data.isPublic;
                        this.classDetails.educationSystems = response.data.data.educationSystems;
                        this.classDetails.educationLevels = response.data.data.educationLevels;
                        this.classDetails.educationGrades = response.data.data.educationGrades;
                        this.classDetails.subjects = response.data.data.subjects;
                        this.classDetails.numOfSlots = response.data.data.numOfSlots;
                        this.classDetails.rating = response.data.data.rating;
                        this.classDetails.creator = response.data.data.creator;
                        this.classDetails.setupCompleted = response.data.data.setupCompleted;
                        this.classDetails.isAcademic = response.data.data.isAcademic;
                        this.classDetails.price = response.data.data.price;
						this.classDetails.lockTopic = response.data.data.lockTopic;
                        this.classDetails.isForTeaching = response.data.data.isForTeaching;
                        this.classDetails.features = response.data.data.features;
                        this.classDetails.assessmentModes = response.data.data.assessmentModes;
                        this.classDetails.startTimestamp = response.data.data.startTimestamp;
                        this.classDetails.endTimestamp = response.data.data.endTimestamp;
                        this.classDetails.thumbnail = response.data.data.thumbnail;
                        this.classDetails.createdAt = response.data.data.createdAt;
                        this.classDetails.updatedAt = response.data.data.updatedAt;

						await localStorage.setItem("class_name", response.data.data.name)
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async recordClassAttendance() {

                let url = `${this.$BACKEND_URL}/classes/attendance`;

                this.$AXIOS.post(url, { uuid: this.$route.params.uuid }).then(async(response) => {
                    					
                }).catch(async(error) => {
					
                });
            },


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>